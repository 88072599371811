import PropTypes from 'prop-types';
import React from 'react';
import moment from "moment";

export default class InvoicePDF extends React.Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    community: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    estimated_cost: PropTypes.number.isRequired
  }

  render() {
    const {company, community, invoice, profile, subscription, estimated_cost} = this.props;

    const subs = parseFloat(invoice.subs).toFixed(2);
    const flex = parseFloat(invoice.flex).toFixed(2);
    const cash = parseFloat(invoice.cash);
    const discount = parseFloat(invoice.discount);
    const total = parseFloat(invoice.total).toFixed(2);
    const cost = (cash + discount + parseFloat(invoice.subs) + parseFloat(invoice.flex)).toFixed(2);

    return(
      <div style={{fontSize:'16px'}}>
        <div className="row">
          <div className="col-xs-6">
            <p style={{fontWeight:'bolder'}}>Pixiebob Ltd Pty</p>
            <p style={{marginBottom:'2px'}}>127 Kent St, Millers Point, Australia</p>
            <p style={{marginBottom:'2px'}}>NSW, AU</p>
            <p style={{marginBottom:'2px'}}>0433 919 567</p>
            <p style={{marginBottom:'2px'}}>hello@pixiebob.io</p>
            <p style={{marginBottom:'2px'}}>www.pixiebob.io</p>
            <p style={{marginBottom:'2px'}}>ABN 56618250785</p>
          </div>
          <div className="col-xs-6" style={{textAlign:'right'}}>
            <img src={'https://pixiebob.s3-ap-southeast-2.amazonaws.com/_invoice/pixiebob_favicon_48kb.png'} alt={''}
                 style={{width:'150px'}}/>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h1 style={{color:'#685df2'}}>INVOICE</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">
            <p style={{fontWeight:'bolder',textTransform:'uppercase',marginBottom:'2px'}}>Bill To</p>
            <p style={{marginBottom:'2px'}}>{profile.first_name} {profile.last_name}</p>
            <p style={{marginBottom:'2px'}}>{company.billing_name}</p>
            <p style={{marginBottom:'2px'}}>{company.billing_address_1},</p>
            <p style={{marginBottom:'2px'}}>{company.billing_address_2 !== null && `${company.billing_address_2},`} {company.billing_province !== null && `${company.billing_province},`} {company.billing_country}</p>
          </div>
          <div className="col-xs-6">
            &nbsp;
          </div>
          <div className="col-xs-3">
            <p style={{marginBottom:'2px'}}>
              <span style={{fontWeight:'bolder',textTransform:'uppercase'}}>Invoice No.</span> {invoice.number}
            </p>
            <p style={{marginBottom:'2px'}}>
              <span style={{fontWeight:'bolder',textTransform:'uppercase'}}>Date</span>
              &nbsp;{moment(invoice.created_at).format('DD/MM/YYYY')}
            </p>
            <p style={{marginBottom:'2px'}}>
              <span style={{fontWeight:'bolder',textTransform:'uppercase'}}>Due Date</span>
              &nbsp;{moment(invoice.due_date).format('DD/MM/YYYY')}
            </p>
            <p style={{marginBottom:'2px'}}>
              <span style={{fontWeight:'bolder',textTransform:'uppercase'}}>Terms</span> {invoice.terms}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr style={{borderTop:'2px solid #685df2'}}/>
          </div>
        </div>
        <br/>
        <br/>
        <div className="row">
          <div className="col-xs-12">
            <table className="table">
              <thead>
              <tr style={{textTransform:'uppercase',backgroundColor:'#e1dffc',color:'#685df2'}}>
                <th>Activity</th>
                <th style={{textAlign:'center',padding:'8px 50px'}}>Qty</th>
                <th>Rate</th>
                <th style={{textAlign:'center',paddingLeft:'115px'}}>Amount</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <p style={{fontWeight:'bolder'}}>{invoice.description}</p>
                  {/*<p>{invoice.notes}</p>*/}
                </td>
                <td style={{textAlign:'center'}}>{invoice.quantity}</td>
                <td>{cost}</td>
                <td style={{textAlign:'right'}}>{cost}</td>
              </tr>
              {/* <tr>
                <td style={{borderTop:'0'}}>&nbsp;</td>
                <td style={{borderTop:'0'}}>&nbsp;</td>
                <td style={{borderTop:'0'}}>&nbsp;</td>
                <td style={{borderTop:'0',textAlign:'right'}}>Subtotal:&nbsp;&nbsp;{cost}</td>
              </tr> */}
              <tr>
                <td>&nbsp;</td>
                <td style={{textTransform:'uppercase'}} colSpan={2}>
                  <p>Subtotal</p>
                  { parseInt(subscription.papt) !== 0 && <p>Subscription offset</p> }
                  { flex > 0 && <p>Flexdays offset</p> }
                  { parseFloat(invoice.discount) !== 0 && <p>Discount</p> }
                  <p>{company.billing_country !== 'AU' ? 'Total' : 'Total Ex-GST'}</p>
                  { company.billing_country === 'AU' && <p>GST</p> }
                  <p>Balance Due</p>
                </td>
                <td>
                  <p style={{textAlign:'right'}}>{cost}</p>
                  { parseInt(subscription.papt) !== 0 && <p style={{textAlign:'right'}}>-{subs}<br/></p>}
                  { flex > 0 && <p style={{textAlign:'right'}}>-{flex}</p> }
                  { discount !== 0 && <p style={{textAlign:'right'}}>-{discount.toFixed(2)}</p> }
                  <p style={{textAlign:'right'}}>{total}</p>
                  { company.billing_country === 'AU' && <p style={{textAlign:'right'}}>{(total * 0.10).toFixed(2)}</p> }
                  <p style={{textAlign:'right',fontWeight:'bolder',fontSize:'26px'}}>
                    US$ {company.billing_country === 'AU' ? (total * 1.10).toFixed(2) : total }
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row" style={{marginTop:'150px'}}>
          {/*<div className="col-xs-6">*/}
          {/*  <p style={{fontWeight:'bolder'}}>Pay via {invoice.pay_via}</p>*/}
          {/*  <p style={{marginBottom:'2px'}}>Account name: Pixiebob Pty Ltd</p>*/}
          {/*  <p style={{marginBottom:'2px'}}>Bank account number: 799378269</p>*/}
          {/*  <p style={{marginBottom:'2px'}}>Bank name: DBS Bank (Hong Kong) Limited</p>*/}
          {/*  <p style={{marginBottom:'2px'}}>SWIFT code: DHBKHKHHXXX</p>*/}
          {/*  <p style={{marginBottom:'2px'}}>Bank code: 016</p>*/}
          {/*  <p style={{marginBottom:'2px'}}>Branch code: 478</p>*/}
          {/*  <p style={{marginBottom:'2px'}}>Bank address: 18th Floor, The Center, 99 Queen’s Road Central, Central. Hong Kong.</p>*/}
          {/*</div>*/}
          <div className="col-xs-3">
            &nbsp;
          </div>

          <div className="col-xs-3" style={{border:'2px solid black',textAlign:'center'}}>
            <p style={{fontWeight:'bolder',marginTop:'10px'}}>Pay via Credit Card</p>
            <p style={{marginTop:'50px',marginBottom:'25px'}}>We'll send a link with details soon.</p>
          </div>

        </div>
      </div>
    )
  }
}