import PropTypes from 'prop-types';
import React from 'react';
import CommunityCard from "./CommunityCard";
import InvitedCard from "./InvitedCard";
import DeclinedCard from "./DeclinedCard";
import RemovedBlockedCard from "./RemovedBlockedCard";
import ArchivedCard from "./ArchivedCard";

export default class CardList extends React.Component {
  static propTypes = {
    communities: PropTypes.array.isRequired,
    updateStateInvitation: PropTypes.func.isRequired,
    updateCropBlob: PropTypes.func.isRequired,
    updateActiveCommunityId: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    onDropImage: PropTypes.func.isRequired,
    handleFavourite: PropTypes.func.isRequired,
    isCollaborator: PropTypes.bool.isRequired,
    updateHide: PropTypes.func.isRequired,
    commState: PropTypes.string.isRequired,
    updateCommunityState: PropTypes.func.isRequired,
    showModalDelete: PropTypes.bool.isRequired,
    updateShowModalDelete: PropTypes.func.isRequired,
    purgeCommunity: PropTypes.func.isRequired,
    counterActiveComm: PropTypes.number.isRequired,
    counterClosedComm: PropTypes.number.isRequired,
    papt: PropTypes.number.isRequired,
    maxPapt: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    subscription: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {commState, counterActiveComm, counterClosedComm, papt, maxPapt, language, colors, subscription} = this.props;

    const cards = this.props.communities.map((community, index) => {
      const cardMainClass = 'px-card-community-container';
      let card = null;

      switch(community.state){
        case 'Enabled':
        case 'Closed':
          switch(community.cu_state){
            case 'Invited':
              card = <div key={community.id} className={cardMainClass}>
                <InvitedCard community={community}
                             updateStateInvitation={this.props.updateStateInvitation}
                             language={language}
                             colors={colors}/>
              </div>;
              break;
            case 'Enabled':
              card = <div key={community.id} className={cardMainClass}>
                <CommunityCard community={community}
                               index={index}
                               updateCropBlob={this.props.updateCropBlob}
                               updateActiveCommunityId={this.props.updateActiveCommunityId}
                               updateShowModal={this.props.updateShowModal}
                               onDropImage={this.props.onDropImage}
                               handleFavourite={this.props.handleFavourite}
                               isCollaborator={this.props.isCollaborator}
                               counterActiveComm={counterActiveComm}
                               counterClosedComm={counterClosedComm}
                               papt={papt}
                               maxPapt={maxPapt}
                               language={language}
                               colors={colors}/>
              </div>;
              break;
            case 'Removed':
            case 'Blocked':
              card = (commState === 'Hidden' || !community.cu_hide) &&
                <div key={community.id} className={cardMainClass}>
                  <RemovedBlockedCard community={community}
                                      updateHide={this.props.updateHide}
                                      language={language}/>
                </div>;
              break;
            case 'Declined':
              card = (commState === 'Hidden' || !community.cu_hide) &&
                <div key={community.id} className={cardMainClass}>
                  <DeclinedCard community={community}
                                updateStateInvitation={this.props.updateStateInvitation}
                                updateHide={this.props.updateHide}
                                language={language}/>
                </div>;
              break;
          }
          break;
        case 'Archived':
          subscription != null ?
          card = <div key={community.id} className={cardMainClass}>
            <ArchivedCard key={community.id}
                          community={community}
                          updateCommunityState={this.props.updateCommunityState}
                          showModalDelete={this.props.showModalDelete}
                          updateShowModalDelete={this.props.updateShowModalDelete}
                          purgeCommunity={this.props.purgeCommunity}
                          language={language}
                          colors={colors}
                          subscription={subscription}/>
          </div> : card = null
          break;
      }

      return card;
    });

    return (
      <div className="px-master-community-cont">
        { cards }
      </div>
    );
  }
}
