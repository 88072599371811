import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ReactModal from "react-modal"

import {getCSRF} from "../../UI/Globals/PX_Funs"
import NewChatModal from './NewChatModal'
import CardList from "./CardList"
import ChDelete from "./Modal/ChDelete"
import ChMainPanel from "./ChMainPanel"

const Chat = ({username, currentUserId, language, avatar, colors, community_id, activities_filter,
                users_id, activities_id, tasks_id, users, community, documents}) => {
  const ai_max_level = community.wonder_chat_ai_max_level;
  const segments = community.segments.map(s => ({...s, modalVisibility: true}));
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [temperature, setTemperature] = useState(0.0)
  const [interviews, setInterviews] = useState('Todos')
  const [intervalId, setIntervalId] = useState(0)
  const [intervalTimerId, setIntervalTimerId] = useState(0)
  const [timerOn, setTimerOn] = useState(false)
  const [lastChat, setLastChat] = useState(null)
  const [lastChatId, setLastChatId] = useState(-1)
  const [isChatApi, setIsChatApi] = useState(true)
  const [showNewChatModal, setShowNewChatModal ] = useState(false)
  const [aiChatRooms, setAiChatRooms] = useState(null);
  const [activeChatRoomId, setActiveChatRoom] = useState(null)
  const [objExport, setObjExport] = useState({})
  const [seconds, setSeconds] = useState(0)
  const [modalAction, setModalAction] = useState('create')
  const [isShowDel, setIsShowDel] = useState(false)
  const [responseEmbeddings, setResponseEmbeddings] = useState({})
  const [loadingResponseEmbeddings, setLoadingResponseEmbeddings] = useState(false)
  const [panel, setPanel] = useState('chat')
  const [aiModel, setAiModel] = useState('gpt-4o-mini')
  const [modalObj, setModalObj] = useState({activities: [], users: []})
  const aiImageProcessingAllowed = community.project_type === 'Mixed' &&
    community.ai_allow_pictures && community.ai_plan === 'aiPlan02'
  const microChatsAllowed = community.project_type === 'Mixed' &&
    (community.ai_plan === 'aiPlan01' || community.ai_plan === 'aiPlan02')

  useEffect(() => {
    getAiChatRooms()
    setObjExport(_initObjectsSearch(users_id, activities_id, tasks_id))
    setModalObj(_initModalObj(activities_filter, users))
  }, [])

  useEffect(() => {
    if(timerOn){
      const newIntervalId = setInterval(() => {
        getLastResponse()
      }, 2000)
      // console.log('newIntervalId:', newIntervalId)
      setIntervalId(newIntervalId)

      const timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1)
      }, 1000)
      setIntervalTimerId(timer)
    } else {
      setSeconds(0)

      if(intervalId){
        // console.log('clear interval intervalId:', intervalId)
        clearInterval(intervalId)
        setIntervalId(0)
      }
      if(intervalTimerId){
        clearInterval(intervalTimerId)
        setIntervalTimerId(0)
      }
    }
    return () => {
      clearInterval(intervalId)
      clearInterval(intervalTimerId)
    }
  }, [timerOn])

  useEffect(() => {
    getChat()
  }, [activeChatRoomId])

  useEffect(() => {
    if(lastChat){
      setLastChatId(lastChat.id)
    }
  }, [lastChat])

  useEffect(() => {
    if(lastChatId !== -1){
      setMessages(messages => [...messages, lastChat])
    }
  }, [lastChatId])

  useEffect(() => {
    // console.log('responseEmbeddings:', responseEmbeddings)
    if(responseEmbeddings?.responses){
      setPanel('list')
    }
  }, [responseEmbeddings])

  useEffect(() => {
    if (Object.keys(responseEmbeddings).length > 0) {
      setLoadingResponseEmbeddings(false);
    }
  }, [responseEmbeddings]);

  const getResponseEmbeddings = chatId => {
    // console.log('getting response embeddings chat id:', chatId)
    setLoadingResponseEmbeddings(true);
    axios.get('/ai/get_chat_responses/' + chatId + '/' + community.project_type)
      .then(r => {
        // console.log(r.data)
        setResponseEmbeddings(r.data)
      })
      .catch(e => console.log(e))
  }

  const handleInterview = interviews => {
    setInterviews(interviews)
    if(lastChatId !== -1) {
      setLastChatId(-1)
    }
  }

  const getAnswer = () => {
    if(query !== '' && activeChatRoomId){
      const dataRes = {
        chat: {
          body: query, ai_chat_room_id: activeChatRoomId, temperature, kind: username, community_id
        }
      }
      //console.log('temperature', temperature)

      axios.post('/ai/save_chat/common', dataRes, getCSRF())
        .then(r => {
          const chatDB = r.data.chat
          // console.log('chatDB:', chatDB)
          setMessages(messages => [...messages, chatDB])
          setLoading(true)
          const url = '/ai/get_answer'
          const data = {
            openai: query, temperature: temperature === '' ? 0 :  temperature, activeChatRoomId, community_id,
            ai_model: aiModel, language: community.language, parent_id: chatDB.id, project_type: community.project_type,
            wonder_chat_pro_max_context: community.wonder_chat_pro_max_context
          }

          axios.post(url, data, getCSRF())
            .then(r => {
              setLoading(false)
              const answerRails = r.data
              // console.log('answerRails:', answerRails)
              setQuery('')
              setTimerOn(true)

            }).catch(e => {
            console.log(e)
            setQuery('')
            setLoading(false)
            setTimerOn(false)
          })
        })
        .catch(e => console.log(e))
    }
  }

  const getAiChatRooms = () => {
    axios
        .get(`/ai/get_chat_rooms/${community_id}/${currentUserId}`)
        .then((response) => {
          setAiChatRooms(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
  }

  const getLastResponse = () => {
    axios.get('/ai/get_last_response/' + activeChatRoomId)
      .then(r => {
        const aiResponse = r.data.response;
        // console.log('aiResponse:', aiResponse);

        if(aiResponse){
          setTimerOn(false);
          setLastChat(aiResponse);

          // Call the function to update the parent message with the child_id
          updateParentWithChildId(aiResponse);
        }
      }).catch(e => console.log(e))
  }

  const updateParentWithChildId = (newResponse) => {
    const parentId = newResponse.parent_id;
    if (parentId !== null) {
      // Find the parent message in the messages array
      const parentMessageIndex = messages.findIndex(message => message.id === parentId);

      if (parentMessageIndex !== -1) {
        // Update the parent message's child_id property
        const updatedParentMessage = {
          ...messages[parentMessageIndex],
          child_id: newResponse.id
        };

        // Replace the original parent message with the updated one
        const updatedMessages = [
          ...messages.slice(0, parentMessageIndex),
          updatedParentMessage,
          ...messages.slice(parentMessageIndex + 1)
        ];

        // Update the messages state
        setMessages(updatedMessages);
      }
    }
  }

  const getChat = () => {
    axios.get('/ai/get_chats/' + activeChatRoomId)
      .then(r => {
        // console.log(r.data)
        setMessages(r.data.chats)
      }).catch(e => console.log(e))
  }

  const toggleShowNewChatModal = (action) => {
    setModalAction(prev => action ? action : prev)
    setShowNewChatModal(showNewChatModal => !showNewChatModal);
    setObjExport(_initObjectsSearch(users_id, activities_id, tasks_id))

    if(showNewChatModal === false) {
      getAiChatRooms(); // <-- Why?
    }
  }

  const toggleEditChatRoom = (activeChatRoomId, action) => {
    console.log('action:', action)
    setActiveChatRoom(activeChatRoomId)
    const room = aiChatRooms.find(e => e.id === activeChatRoomId)

    setObjExport(prev => ({...prev, idActs: room.sources.activities_ids,
      idPars: room.sources.participants_ids, idSegs: room.sources.segments_ids,
      dataTypes: room.sources.type_of_data, taskTypes: room.sources.task_types, idDocs: room.sources.documents_ids}))
    setShowNewChatModal(showNewChatModal => !showNewChatModal)
    setModalAction(action)
  }

  const updateObjExpAllNone = (type, filter) => {
    // console.log(type, filter)
    // console.log('objExport:', objExport)
    const currentObjExport = { ...objExport }
    // console.log('currentObjExport:', currentObjExport)

    switch(type) {
      case 'Activities':
        switch(filter) {
          case 'All':
            currentObjExport.idActs = activities_filter.map(a => a.id)
            break
          case 'None':
            currentObjExport.idActs = []
            break
        }
        break
      case 'Participants':
        currentObjExport.idPars = filter === 'All' ? users.map(user => user.id) : []
        break
      case 'Segments':
        currentObjExport.idSegs = filter === 'All' ? segments.map(segment => segment.id) : []
        break
    }
    // console.log('currentObjExport:', currentObjExport)
    setObjExport(currentObjExport)
  }

  const updateObjExportActions = (id, type) => {
    setObjExport(_updateObjExport(id, type))
  }

  const _updateObjExport = (id, type) => {
    // console.log(id, type);
    let newObjExport;
    let idObjs = [];

    if(typeof id !== 'boolean'){
      if(objExport[type].find(objId => objId === id) !== undefined){
        newObjExport = {...objExport, [type]: objExport[type].filter(objId => objId !== id), cfId: null}
      } else {
        newObjExport = {...objExport, [type]: objExport[type].concat(id), cfId: null}
      }
    } else {
      if(type === 'idPars'){
        idObjs = id ? users.map(user => user.user_id) : [];
      } else {
        idObjs = id ? segments.map(segment => segment.id) : [];
      }

      newObjExport = {...objExport, [type]: idObjs, cfId: null};
    }

    if (type === 'idSegs') {
      const selectedSegmentIds = newObjExport.idSegs
      const participantsIds = users.filter(p => p.segments.some(s => selectedSegmentIds.includes(s.id))).map(p => p.id)
      newObjExport = { ...newObjExport, idPars: participantsIds, cfId: null }
    }

    // console.log('newObjExport:', newObjExport);
    return newObjExport;
  }

  const _initModalObj = (activities, users) => {
    return { activities: activities.map(a => ({...a, isVis: true})), users:  users.map(u => ({...u, isVis: true}))}
  }

  // Actually this is ObjExport
  const _initObjectsSearch = (id_Pars, id_Acts, id_Tasks) => {
    let idPars = []
    let idActs =  []
    let idTasks = []
    let idSegs = segments.map(task => task.id)
    const dataTypes = ['text']
    const taskTypes = microChatsAllowed ? ['Open End', 'MicroChat'] : ['Open End']
    const idDocs = []

    if(id_Pars !== '-1'){
      idPars = id_Pars === 'all' ? users.map(user => user.id) : JSON.parse("[" + id_Pars + "]")
      idActs = id_Acts === 'all' ? activities_filter.map(task => task.id) : JSON.parse("[" + id_Acts + "]")
      if(id_Tasks === 'all'){
        activities_filter.forEach(activity => {
          idActs.forEach(idAct => {
            if(activity.id === idAct){
              activity.questions.forEach(task => {
                idTasks.push(task.id)
              })
            }
          });
        });
      } else {
        idTasks = JSON.parse("[" + id_Tasks + "]");
      }
    }
    //console.log('idPars:', idPars, 'idActs:', idActs, 'idTasks:', idTasks);
    return {
      idPars, idActs, idTasks, idSegs, dataTypes, taskTypes, idDocs, idTags: [],
      actDates: {name: 'creation', startDate: null, endDate: null},
      resStates: {accepted: true, completed: true, draft: true, empty: true}
    };
  }

  let btnMsg
  if(timerOn){
    btnMsg = <><FontAwesomeIcon icon={['fas','spinner']} spin size="lg"/> {seconds} s.</>
  } else {
    btnMsg = <FontAwesomeIcon icon={['fas','paper-plane']} size="lg"/>
  }

  const toggleDelMod = () => {
    setIsShowDel(prev => !prev)
  }

  const deleteChatRoom = () => {
    const url = '/ai/delete_chatroom/' + activeChatRoomId

    axios.post(url, {}, getCSRF())
      .then(r => {
        // console.log('r:', r)
        getAiChatRooms()
        setMessages([])
        toggleDelMod()
      })
      .catch(e => console.log(e))
  }

  const onClickChatCard = ai_chat_room_id => {
    setActiveChatRoom(ai_chat_room_id)
    setPanel('chat')
  }

  return (
    <div className="container-fluid px-transcript-container" style={{padding:'40px 40px 0'}}>
      <div className="card px-card" style={{maxHeight:'80vh'}}>
        <div className="card-height-indicator"/>
        <div className="card-content">
          <div className="card-body" style={{height:'100%'}}>
            <div className="row px-ai-flex-container" style={{height:'100%'}}>
              <CardList toggleShowNewChatModal={toggleShowNewChatModal}
                        onClickChatCard={onClickChatCard}
                        interviews={interviews}
                        activeChatRoomId={activeChatRoomId}
                        handleInterview={handleInterview}
                        aiChatRooms={aiChatRooms}
                        colors={colors}
                        language={language}
                        toggleEditChatRoom={toggleEditChatRoom}
                        setModalAction={setModalAction}
                        toggleDelMod={toggleDelMod}/>
              <ChMainPanel getAnswer={getAnswer}
                           setQuery={setQuery}
                           btnMsg={btnMsg}
                           avatar={avatar}
                           lastChatId={lastChatId}
                           getResponseEmbeddings={getResponseEmbeddings}
                           activeChatRoomId={activeChatRoomId}
                           setLastChatId={setLastChatId}
                           colors={colors}
                           messages={messages}
                           setTemperature={setTemperature}
                           language={language}
                           isChatApi={isChatApi}
                           setIsChatApi={setIsChatApi}
                           currentUserId={currentUserId}
                           timerOn={timerOn}
                           query={query}
                           setPanel={setPanel}
                           panel={panel}
                           responseEmbeddings={responseEmbeddings}
                           setAiModel={setAiModel}
                           ai_max_level={ai_max_level}
                           loadingResponseEmbeddings={loadingResponseEmbeddings}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showNewChatModal &&
        <ReactModal isOpen={showNewChatModal} contentLabel="New Chat Modal"
                    shouldCloseOnOverlayClick={true} onRequestClose={toggleShowNewChatModal}
                    className="px-modal-content" overlayClassName="px-modal-overlay">
            <NewChatModal language={language}
                          colors={colors}
                          community_id={community_id}
                          currentUserId={currentUserId}
                          toggleShowNewChatModal={toggleShowNewChatModal}
                          getAiChatRooms={getAiChatRooms}
                          allAvailableActs={modalObj.activities}
                          objExport={objExport}
                          updateObjExportActions={updateObjExportActions}
                          users={users}
                          segments={segments}
                          genderAsSegment={community.gender_as_segment}
                          room={aiChatRooms.find(e => e.id === activeChatRoomId)}
                          modalAction={modalAction}
                          projectType={community.project_type}
                          documents={documents}
                          updateObjExpAllNone={updateObjExpAllNone}
                          aiImageProcessingAllowed={aiImageProcessingAllowed}
                          microChatsAllowed={microChatsAllowed}
            />
          </ReactModal>
      }
      {
        isShowDel &&
        <ReactModal isOpen={isShowDel} contentLabel="Delete Chat Card"
                    shouldCloseOnOverlayClick={true} onRequestClose={toggleDelMod}
                    className="px-modal-content" overlayClassName="px-modal-overlay">
          <ChDelete toggleModal={toggleDelMod} colors={colors} language={language}
                    deleteChatRoom={deleteChatRoom}/>
        </ReactModal>
      }
    </div>
  )
}

Chat.propTypes = {
  community: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  colors: PropTypes.object.isRequired,
  community_id: PropTypes.number.isRequired,
  activities_filter: PropTypes.array.isRequired,
  users_id: PropTypes.string.isRequired,
  activities_id: PropTypes.string.isRequired,
  tasks_id: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  documents: PropTypes.array
}

export default Chat