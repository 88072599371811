import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MediaQuery from "react-responsive";

import {getCountryName} from "../../UI/Globals/PX_Funs";
import R568Search from "../../Responsive/568px/R568Search";
import ReactModal from "react-modal";
import HelpTip from "../../HelpTip";

export default class UL_Filters extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    counter: PropTypes.array.isRequired,
    flagInvited: PropTypes.bool.isRequired,
    flagDraft: PropTypes.bool.isRequired,
    collaborators: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    checkEnabled: PropTypes.bool.isRequired,
    searchTerm: PropTypes.string.isRequired,
    search: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    sortByAlphabetically: PropTypes.func.isRequired,
    communityId: PropTypes.number.isRequired,
    social_flag: PropTypes.bool.isRequired,
    show_part_list: PropTypes.bool.isRequired,
    communityState: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    participants: PropTypes.array.isRequired,
    updateShowModalQI: PropTypes.func.isRequired,
    updateShowModalGI: PropTypes.func.isRequired,
    showModalMobile: PropTypes.bool.isRequired,
    updateShowModalMobile: PropTypes.func.isRequired,
    maxPapt: PropTypes.number,
    papt: PropTypes.number,
    updateOpenPMFromOutside: PropTypes.func,
    colors: PropTypes.object.isRequired,
    hasParticipants: PropTypes.bool,
    hasCollaborators: PropTypes.bool,
    helpStores: PropTypes.object,
    userId: PropTypes.number,
    genericLinkToken: PropTypes.string,
    allowJoinWGenericLink: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      papt: props.papt,
      helpStores: this.props.helpStores,
      showFilters: false
    }
  }

  render() {
    const {
      role_2, counter, flagInvited, flagDraft, collaborators, communityId, type, social_flag,
      show_part_list, communityState, language, from, maxPapt, papt, showModalMobile, colors,
      hasParticipants, hasCollaborators, userId, genericLinkToken, allowJoinWGenericLink
    } = this.props;
    const {helpStores, showFilters} = this.state;

    const userTypeForTooltip = type === 'Participant' ?
      {en: 'participant', es: 'participante'} : {en: 'collaborator', es: 'colaborador'}

    const i18n = {
      en: {
        search: 'Search', sortBy: 'Sort by', moderators: 'MODERATORS',
        actives: 'Active', invited: 'Invited', drafts: 'Drafts',
        declined: 'Declined', blocked: 'Blocked', status: 'Status',
        username: 'Username', role: 'Role', gender: 'Gender',
        location: 'Location', segment: 'Segment',
        of: 'of', addParticipantsTip: 'Invite participants here', addCollaboratorsTip: 'Invite moderators or clients here',
        btnGI: 'Link', btnQI: 'Quick', btnMI: 'Multi',
        slots: 'Slots', invitationsRemaining: 'participant slots remaining',
        btnGITooltip: 'Invite participants by sharing a generic link. Anyone with the link can join.',
        btnQITooltip: `Quickly invite one ${userTypeForTooltip[language]} by entering an email addresses. Only the invited emails allow to sign up.`,
        btnMITooltip: `Invite multiple ${userTypeForTooltip[language]}s by entering their email addresses. Only those who sign up with the invited email can join.`,
        inviteHere: 'Invite here',
        noParticipantSlotsAvailable: 'No more participant slots available.',
        sortAndFilter: 'Sort and filter'
      },
      es: {
        search: 'Buscar', sortBy: 'Ordenar por', moderators: 'MODERADORES',
        actives: 'Activos', invited: 'Invitados', drafts: 'Drafts', declined: 'Declinados', blocked: 'Bloqueados',
        status: 'Estado', username: 'Username', role: 'Función', gender: 'Género', location: 'Ubicación',
        segment: 'Segmento', of: 'de', addParticipantsTip: 'Invita participantes aquí',
        addCollaboratorsTip: 'Invita moderadores o clientes aquí',
        slots: 'Slots', invitationsRemaining: 'lugares para participantes disponibles',
        btnGI: 'Link', btnQI: 'Quick', btnMI: 'Multi',
        btnGITooltip: 'Invita a participantes compartiendo un link genérico. Cualquiera con el link puede unirse.',
        btnQITooltip: `Invita rápido a un ${userTypeForTooltip[language]} ingresando su dirección de email. Solo el email invitado puede unirse.`,
        btnMITooltip: `Invita a múltiples ${userTypeForTooltip[language]}s ingresando sus direcciones de email. Solo aquellos que se registren con el email invitado pueden unirse.`,
        inviteHere: 'Invita',
        noParticipantSlotsAvailable: 'No hay más espacios disponibles para participantes.',
        sortAndFilter: 'Filtra y ordena'
      }
    };

    let displayHelpTip = (type === 'Participant' && !hasParticipants) ?
      true : (type === 'Collaborator' && !hasCollaborators) ? true : false
    let helpTip = <HelpTip showRequisites={displayHelpTip}
                           tipKey={type === 'Participant' ? "addParticipantsTip" : "addCollaboratorsTip"}
                           text={type === 'Participant' ? i18n[language].addParticipantsTip : i18n[language].addCollaboratorsTip}
                           colors={colors}
                           communityId={communityId}
                           userId={userId}
                           initialHelpStores={helpStores}
                           arrowDirection={'down'}
                           top={type === 'Participant' ? '-55px' : '-65px'}
                           left={type === 'Participant' ? '10px' : '-40px'}
    />

    const invitationSlotsRemaining = maxPapt - papt;
    const areInvitationSlotsAvailable = papt < maxPapt;

    let participantCounter = type === 'Participant' &&
      <div className="px-check-btn"
           style={{marginLeft: '10px'}}
           data-tooltip-content={`${invitationSlotsRemaining} ${i18n[language].invitationsRemaining}`}>
        <span className="btn btn-filter-list"
              style={{
                cursor: 'default',
                border: 'none'
              }}
              onClick={(e) => e.stopPropagation()}>
          <span className="text" style={{marginRight: '10px'}}>{i18n[language].slots}</span>
          <div className={`counter ${areInvitationSlotsAvailable ? 'alert-success' : 'alert-error'}`}>
            <span style={{color: '#262e37'}}>{maxPapt - papt}</span>
          </div>
        </span>
      </div>;

    let btnGI = type === 'Participant' ?
      <button onClick={this.props.updateShowModalGI}
              className="btn px-btn sm btn-blue-base btn-list-invite lg"
              disabled={!areInvitationSlotsAvailable}
              style={{backgroundColor: colors.color1[3]}}
              data-tooltip-content={areInvitationSlotsAvailable ? i18n[language].btnGITooltip : i18n[language].noParticipantSlotsAvailable}
      >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '-2px'}}>
            <span style={{marginRight: '5px'}}>
              <FontAwesomeIcon icon={['fas', 'link']} style={{fontSize: '14px'}}/>
            </span>
          <span className="text-lg">{i18n[language].btnGI}</span>
          <span className="text-sm">{i18n[language].btnGI}</span>
        </div>
        {helpTip}
      </button> : ''

    let btnQI = type === 'Participant' ?
      <button onClick={this.props.updateShowModalQI}
              className="btn px-btn sm btn-blue-base btn-list-invite lg"
              disabled={!areInvitationSlotsAvailable}
              style={{backgroundColor: colors.color1[3]}}
              data-tooltip-content={areInvitationSlotsAvailable ? i18n[language].btnQITooltip : i18n[language].noParticipantSlotsAvailable}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '-2px'}}>
            <span style={{marginRight: '5px'}}>
              <FontAwesomeIcon icon={['fas', 'envelope']} style={{fontSize: '14px'}}/>
            </span>
          <span className="text-lg">{i18n[language].btnQI}</span>
          <span className="text-sm">{i18n[language].btnQI}</span>
        </div>
        {helpTip}
      </button> :
      <button onClick={this.props.updateShowModalQI}
              className="btn px-btn sm btn-blue-base btn-list-invite lg"
              style={{backgroundColor: colors.color1[3]}}
              data-tooltip-content={i18n[language].btnQITooltip}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '-2px'}}>
            <span style={{marginRight: '5px'}}>
              <FontAwesomeIcon icon={['fas', 'envelope']} style={{fontSize: '14px'}}/>
            </span>
          <span className="text-lg">{i18n[language].btnQI}</span>
          <span className="text-sm">{i18n[language].btnQI}</span>
        </div>
        {helpTip}
      </button>;
    let btnMI = type === 'Participant' ?
      <button onClick={() => this._forwardAction(communityId, type)}
              className="btn px-btn sm btn-blue-base btn-list-invite lg"
              disabled={!areInvitationSlotsAvailable}
              style={{backgroundColor: colors.color1[3]}}
              data-tooltip-content={areInvitationSlotsAvailable ? i18n[language].btnMITooltip : i18n[language].noParticipantSlotsAvailable}
      >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '-2px'}}>
          <span style={{marginRight: '5px'}}>
            <FontAwesomeIcon icon={['fas', 'envelope']} style={{fontSize: '14px'}}/>
          </span>
          <span className="text-lg">{i18n[language].btnMI}</span>
          <span className="text-sm">{i18n[language].btnMI}</span>
        </div>
      </button> :
      <button onClick={() => this._forwardAction(communityId, type)}
                          className="btn px-btn sm btn-blue-base btn-list-invite lg"
                          style={{backgroundColor: colors.color1[3]}}
                          data-tooltip-content={i18n[language].btnMITooltip}
      >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '-2px'}}>
          <span style={{marginRight: '5px'}}>
            <FontAwesomeIcon icon={['fas', 'envelope']} style={{fontSize: '14px'}}/>
          </span>
          <span className="text-lg">{i18n[language].btnMI}</span>
          <span className="text-sm">{i18n[language].btnMI}</span>
        </div>
      </button>;

    if (role_2 === 'Observer' || role_2 === '') {
      btnQI = '';
      btnMI = null;
      btnGI = ''
    }

    if (type === 'Collaborator' && role_2 === 'Recruiter') {
      btnQI = null;
      btnMI = null;
      btnGI = null;
    }

    const flagInvitedClass = flagInvited ? 'counter alert-error' : 'counter';
    const flagDraftClass = flagDraft ? 'counter alert-error' : 'counter';

    const collaboratorsList = collaborators !== undefined && collaborators.map(collaborator => {
      if (collaborator.state === 'Enabled' &&
        (collaborator.role_2 === 'Owner' || collaborator.role_2 === 'Moderator')) {
        const regionCountry = collaborator.state_country === '' ? getCountryName(collaborator.country) :
          collaborator.state_country + ', ' + getCountryName(collaborator.country);

        return (
          <div key={collaborator.cu_id} className="moderator-item">
            <img src={collaborator.avatar !== '' ? collaborator.avatar : '/assets/user.png'} alt="" className="avatar"/>
            <span className="username">{collaborator.username}</span>
            <div className="interactions-container">
              {
                (collaborator.gender !== '' && collaborator.show_gender) &&
                <div className="px-genders-container">
                  <div className="gender-container" data-tooltip-content={collaborator.gender}>
                    {this._setIconGender(collaborator.gender)}
                  </div>
                </div>
              }
              {
                ((collaborator.country !== '' && collaborator.country) && collaborator.show_location) &&
                <div className="px-country-container" data-tooltip-content={regionCountry}>
                  <span>{collaborator.country}</span>
                </div>
              }
              <FontAwesomeIcon
                onClick={() => this.props.updateOpenPMFromOutside('PM', collaborator.username, collaborator.user_id)}
                className="message" icon={['fas', 'envelope']} style={{cursor: 'pointer'}}/>
            </div>
          </div>
        )
      }
    });

    let searchFilter = <div>
      <FontAwesomeIcon onClick={this.props.updateShowModalMobile}
                       icon={['fas', 'magnifying-glass']} className="px-icon-search-responsive main-dashboard"/>

      {
        showModalMobile &&
        <ReactModal isOpen={showModalMobile} contentLabel="Modal Users Search"
                    shouldCloseOnOverlayClick={true} className="my-modal-content" overlayClassName="my-modal-overlay"
                    onRequestClose={this.props.updateShowModalMobile}>
          <R568Search from={'Users List'}
                      type={type}
                      role_2={role_2}
                      updateShowModalMobile={this.props.updateShowModalMobile}
                      handleChange={this.props.handleChange}
                      searchTerm={this.props.searchTerm}
                      handleSearch={this.props.search}
                      sortBy={this.props.sortBy}
                      language={language}
                      colors={colors}/>
        </ReactModal>
      }
    </div>;

    return (
      <div>
        <div className="px-search-bar-dash-comm-cont user-list"
             role="search"
             style={{marginBottom: '20px', flexWrap: 'nowrap'}}
        >
          {
            (type === 'Participant' && from !== 'CollaboratorUsersList') &&
            <MediaQuery maxDeviceWidth={480}>
              <div className="px-filter-moderator-responsive">
                {searchFilter}
                <div className="text-moderators" data-toggle="collapse" data-target="#moderators_list">
                  <span className="text">{i18n[language].moderators}</span>
                  {
                    (social_flag || show_part_list) &&
                    <FontAwesomeIcon icon={['far', 'square-minus']} className="icon"/>
                  }
                </div>
              </div>
            </MediaQuery>
          }
          {
            this.props.from === 'CollaboratorUsersList' &&
            <MediaQuery maxDeviceWidth={480}>
              <div className="px-filter-collaborator-responsive"
                   style={{width: type === 'Participant' ? '70%' : '45%'}}>
                {communityState !== 'Closed' && <div style={{
                  color: '#7c8593',
                  fontFamily: 'Sharp Sans ExtraBold',
                  fontSize: '10px',
                  letterSpacing: '1.2px',
                  textTransform: 'uppercase',
                  textAlign: 'center'
                }}>
                  {i18n[language].inviteHere}
                </div>}
                {communityState !== 'Closed' && allowJoinWGenericLink && btnGI}
                {communityState !== 'Closed' && btnQI}
              </div>
            </MediaQuery>
          }
          {
            (social_flag || show_part_list) &&
            <div className="px-filter-left-container dashboard-collaborator">
              <div className="px-input-icon main-dashboard form-group">
                <input name="searchTerm" onChange={this.props.handleChange} value={this.props.searchTerm}
                       onKeyDown={this.keyPress} type="text" className="form-control icon-right"
                       placeholder={i18n[language].search}/>
                <FontAwesomeIcon icon={['fas', 'magnifying-glass']} className="px-icon-right color-gray-base"
                                 onClick={() => this.props.search(this.props.type)}/>
              </div>
            </div>
          }

          <div className="px-filter-right-container dashboard-collaborator coll-list">
            <div style={{
              display: 'flex',
              position: 'relative',
              borderTop: '1px solid #DADDE1',
              paddingTop: '5px'
            }}>
              {communityState !== 'Closed' && <span style={{
                color: '#7c8593',
                position: 'absolute',
                top: '-19px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                fontFamily: 'Sharp Sans ExtraBold',
                fontSize: '10px',
                letterSpacing: '1.2px',
                textTransform: 'uppercase',
                textAlign: 'center',
                justifyContent: 'center',
                display: this.props.role_2 === 'Owner' || this.props.role_2 === 'Moderator' ? 'flex' : 'none'
              }}>
                {i18n[language].inviteHere}
              </span>}
              {communityState !== 'Closed' && participantCounter}
              {communityState !== 'Closed' && allowJoinWGenericLink && btnGI}
              {communityState !== 'Closed' && btnQI}
              {communityState !== 'Closed' && btnMI}
            </div>
            <div className={'participant-list-filter'}
                 style={{color: '#7c8593', marginLeft: '30px', cursor: 'pointer', fontSize: '20px', marginTop: '5px'}}
                 onClick={() => this.handleShowFilters()}
                 data-tooltip-content={i18n[language].sortAndFilter}
            >
              <FontAwesomeIcon icon={['fas', 'filter']}/>
            </div>
          </div>
        </div>
        {
          (this.props.type === 'Participant' && this.props.from !== 'CollaboratorUsersList') &&
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-lg-offset-2">
              <div className="px-moderator-container">
                <div className="text-moderators" data-toggle="collapse" data-target="#moderators_list">
                  <span className="text">{i18n[language].moderators}</span>
                  {
                    (social_flag || show_part_list) &&
                    <FontAwesomeIcon icon={['far', 'square-minus']} className="icon"/>
                  }
                </div>
                <div className="collapse in" id="moderators_list">
                  <div className="moderators-elements-container ">
                    {collaboratorsList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          this.props.from === 'CollaboratorUsersList' && role_2 !== "Observer" &&
          <div className="row" style={{display: showFilters ? 'flex' : 'none'}}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-check-filter-container">
                {
                  from === 'CollaboratorUsersList' &&
                  <div className="px-select px-select-sort"
                       style={{marginRight: '10px'}}
                       data-tooltip-content={i18n[language].sortBy}
                  >
                    <select name="sortBy" onChange={this.props.handleChange} value={this.props.sortBy}>
                      <option value="Status">{i18n[language].status}</option>
                      <option value="Username">{i18n[language].username}</option>
                      {role_2 !== 'Observer' && <option value="Email">Email</option>}
                      {type === 'Collaborator' && <option value="Role">{i18n[language].role}</option>}
                      {type === 'Participant' && <option value="Gender">{i18n[language].gender}</option>}
                      {type === 'Participant' && <option value="Location">{i18n[language].location}</option>}
                      {/* this.props.type === 'Participant' && <option value="Segment">{i18n[language].segment}</option> */}
                    </select>
                  </div>
                }

                <div className="px-check-btn" data-tooltip-content={i18n[language].actives}>
                  <input type="checkbox" name="checkEnabled" className="hidden-ch" onClick={this.props.handleChange}
                         id={"ch-active1"} style={{display: 'none'}}/>
                  <label htmlFor="ch-active1"
                         className="btn btn-filter-list"
                         style={{alignItems: 'center', minWidth: '80px'}}>
                    {/*<span className="text">{i18n[language].actives}</span>*/}
                    <span><FontAwesomeIcon icon={['fas', 'wave-pulse']}/></span>
                    <div className="counter">
                      <span>{counter[0]}</span>
                    </div>
                  </label>
                </div>

                <div className="px-check-btn" data-tooltip-content={i18n[language].invited}>
                  <input type="checkbox" name="checkInvited" className="hidden-ch" onClick={this.props.handleChange}
                         id={"ch-invited"} style={{display: 'none'}}/>
                  <label htmlFor="ch-invited" className="btn btn-filter-list"
                         style={{alignItems: 'center', minWidth: '80px'}}>
                    {/*<span className="text">{i18n[language].invited}</span>*/}
                    <span><FontAwesomeIcon icon={['fas', 'reply-clock']} flip="horizontal"/></span>
                    <div className={flagInvitedClass}>
                      <span>{counter[1]}</span>
                    </div>
                  </label>
                </div>

                <div className="px-check-btn" data-tooltip-content={i18n[language].drafts}>
                <input type="checkbox" name="checkPending" className="hidden-ch" onClick={this.props.handleChange}
                         id={"ch-pending"} style={{display: 'none'}}/>
                  <label htmlFor="ch-pending" className="btn btn-filter-list"
                         style={{alignItems: 'center', minWidth: '80px'}}>
                    {/*<span className="text">{i18n[language].drafts}</span>*/}
                    <span><FontAwesomeIcon icon={['fas', 'clock']} flip="horizontal"/></span>
                    <div className={flagDraftClass}>
                      <span>{counter[2]}</span>
                    </div>
                  </label>
                </div>

                <div className="px-check-btn" data-tooltip-content={i18n[language].declined}>
                  <input type="checkbox" name="checkDeclined" className="hidden-ch" onClick={this.props.handleChange}
                         id={"ch-decline"} style={{display: 'none'}}/>
                  <label htmlFor="ch-decline" className="btn btn-filter-list"
                         style={{alignItems: 'center', minWidth: '80px'}}>
                    {/*<span className="text">{i18n[language].declined}</span>*/}
                    <span><FontAwesomeIcon icon={['fas', 'ban']} flip="horizontal"/></span>
                    <div className="counter">
                      <span>{counter[3]}</span>
                    </div>
                  </label>
                </div>

                <div className="px-check-btn" data-tooltip-content={i18n[language].blocked}>
                  <input type="checkbox" name="checkBlocked" className="hidden-ch" onClick={this.props.handleChange}
                         id={"ch-blocked"} style={{display: 'none'}}/>
                  <label htmlFor="ch-blocked" className="btn btn-filter-list"
                         style={{alignItems: 'center', minWidth: '80px'}}>
                    {/*<span className="text">{i18n[language].blocked}</span>*/}
                    <span><FontAwesomeIcon icon={['fas', 'lock']} flip="horizontal"/></span>
                    <div className="counter">
                      <span>{counter[4]}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  handleShowFilters() {
    this.setState((prevState) => ({
      showFilters: !prevState.showFilters
    }));
  }

  /*updateShowModalMobile = () => {
    console.log('updateShowModalMobile');
    this.setState(prevState => ({showModalMobile: !prevState.showModalMobile}));
  };*/

  _forwardAction(communityId, type) {
    window.location.href = '/users/invite_via_email/' + communityId + '/' + type;
  }

  keyPress = (event) => {
    if (event.keyCode === 13) {
      this.props.search(this.props.type);
    }
  };

  _setIconGender(gender) {
    let icon = null;

    switch (gender) {
      case 'Female':
        icon = 'venus';
        break;
      case 'Male':
        icon = 'mars';
        break;
      case 'Other':
        icon = 'transgender-alt';
        break;
    }

    return <FontAwesomeIcon icon={['fal', icon]}/>;
  }
}
